import React from "react";
import propTypes from "prop-types";
import NavLogo from "../atoms/ImageIcon";
import NavLink from "../molecules/NavLink";
import NavBarProfile from "../molecules/NavBarProfile";
import { Button } from "atoms";
import { useRouter } from "next/router";
import ImageIcon from "../atoms/ImageIcon";
import { checisReferralActive, getAuth } from "services/identity.service";
import OldNavLink from "molecules/OldNavLink";

function Header({
  kidName,
  isB2B,
  photo,
  orderHref,
  reportHref,
  setDropdownStatus,
  dropdownStatus,
  onLogoClick,
  settings,
}) {
  const router = useRouter();
  const auth = getAuth();

  const isRefrralActive = checisReferralActive(settings);

  return (
    <>
      <div className="flex fixed top-0 left-0 z-50 justify-between shadow-sm items-center h-20 w-full bg-white">
        <div className="ml-3 md:ml-7">
          <div className="flex items-center">
            <NavLogo src="/images/navLogo.svg" handleClick={onLogoClick} />

            {auth?.roles.includes("Parent Offline B2B") ||
            isB2B ? null : isRefrralActive ? (
              <Button
                className="animate-pulse px-1.5 md:px-4 ml-2 sm:ml-6 md:ml-10 text-[8px] md:text-sm bg-primary-900 text-white hover:bg-primary-900 border border-primary-900  py-2  rounded-full"
                onClick={() => router.push(`/refer-and-earn`)}>
                Refer & Earn{" "}
                <span className="border-2 border-primary-500 rounded-full px-1">
                  {" "}
                  ₹
                </span>
              </Button>
            ) : null}
          </div>
        </div>
        <div className="flex ml-1">
          {isB2B ? null : auth?.roles.includes("Parent Offline B2B") ? (
            <div className="mr-4 md:mr-32">
              <OldNavLink
                src="/images/Bagblack.svg"
                label="Report"
                href={reportHref}
              />
            </div>
          ) : (
            <div className="mr-4 md:mr-32">
              <OldNavLink
                src="/images/Bagblack.svg"
                label="Orders"
                href={orderHref}
              />
            </div>
          )}
          <div
            className="mr-5"
            onClick={() => setDropdownStatus(!dropdownStatus)}>
            <NavBarProfile src={photo} lebel={kidName} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
Header.propTypes = {
  profilesrc: propTypes.string,
  profilename: propTypes.string,
  onclick: propTypes.func,
};
