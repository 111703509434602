import React from "react";
import propTypes from "prop-types";
import NavLogo from "../atoms/ImageIcon";
import NavLink from "../molecules/NavLink";
import NavBarProfile from "../molecules/NavBarProfile";
import { Button, Text } from "atoms";
import { useRouter } from "next/router";
import ImageIcon from "../atoms/ImageIcon";
import { checisReferralActive, getAuth } from "services/identity.service";
import { getSubscriptionDetails } from "services/plans.service";
import { useEffect, useState } from "react";
import NewNavbarProfile from "molecules/NewNavbarProfile";
import Avatar from "../atoms/Avatar";
import NewAvtar from "atoms/NewAvtar";
const R = require("ramda");
function NewHeader({
  kidName,
  isB2B,
  photo,
  orderHref,
  reportHref,
  setDropdownStatus,
  dropdownStatus,
  onLogoClick,
  goToprofilePage,
  settings,
  planName,
  goToHomePage,
  kid,
  planSetting,
  viewPlans,
  showChildListAndprofile,
  showChildList,
  otherKid,
  kidId,
  kidList,
  viewOrdeDetails,
  goToAccountDetailspage,
}) {
  const router = useRouter();
  const auth = getAuth();
  const [value, setvaluePlan] = useState([]);

  let planChanged = router.query?.planChanged || false;

  const getKidSubscription = async () => {
    const res = await getSubscriptionDetails(
      auth.id,
      router.query.id || kidId,
      auth.token
    );

    const subRes = await R.pathOr(null, ["data", "entity"], res);

    setvaluePlan(subRes);
  };

  useEffect(async () => {
    await getKidSubscription();
  }, [planChanged]);
  const [truncatedName, setTruncatedName] = useState("");

  useEffect(() => {
    const originalName = value?.activeSubscription?.Plan?.name;
    if (originalName && originalName.length > 12) {
      const truncated = originalName.slice(0, 12) + "...";
      setTruncatedName(truncated);
    } else {
      setTruncatedName(originalName);
    }
  }, [value]);

  console.log("valuevaluevalue", kidName);
  const isRefrralActive = checisReferralActive(settings);

  // MonkeyBox On-Demand Plan
  return (
    <>
      <div className="flex fixed top-0 left-0 z-[1000] justify-between shadow-sm items-center md:h-20 h-28 w-full bg-white">
        <div className="ml-3 md:ml-7">
          <div className="flex items-center">
            <NavLogo
              src="/images/navLogo.svg"
              handleClick={onLogoClick}
              className="md:flex hidden"
            />

            {/* {auth?.roles.includes("Parent Offline B2B") ||
            isB2B ? null : isRefrralActive ? (
              <Button
                className="animate-pulse px-1.5 md:px-4 ml-2 sm:ml-6 md:ml-10 text-[8px] md:text-sm bg-primary-900 text-white hover:bg-primary-900 border border-primary-900  py-2  rounded-full"
                onClick={() => router.push(`/refer-and-earn`)}>
                Refer & Earn{" "}
                <span className="border-2 border-primary-500 rounded-full px-1">
                  {" "}
                  ₹
                </span>
              </Button>
            ) : null} */}
            {/* <div className="flex  mx-4">
              <div className="flex flex-col">
                <Text className="font-semibold text-md my-1">
                  21 March 2023
                </Text>
                <Text className=" text-sm mt-1">Enjoy curted menu</Text>
              </div>
            </div> */}
            <div className=" md:ml-[3rem] md:flex hidden justify-center items-center mx-4">
              <div className="">
                <Button
                  className="font-semibold text-md "
                  onClick={goToHomePage}
                >
                  Home{" "}
                </Button>
              </div>

              <div className="mx-8">
                <Button
                  className="font-semibold text-md "
                  onClick={viewOrdeDetails}
                >
                  Orders{" "}
                </Button>
              </div>
              <div className="flex mx-2">
                <Button
                  className="font-semibold text-md "
                  onClick={planSetting}
                >
                  Profile
                </Button>
              </div>
            </div>
          </div>
        </div>{" "}
        <div className="flex ml-1">
          {isB2B ? null : auth?.roles.includes("Parent Offline B2B") ? (
            <div className="mr-4 md:mr-32">
              <NavLink
                src="/images/Bagblack.svg"
                label="Report"
                href={reportHref}
              />
            </div>
          ) : (
            <div className="mr-4">
              {/* <NavLink
                src="/images/Bagblack.svg"
                label="Orders"
                href={orderHref}
              /> */}
            </div>
          )}
          <div className="flex flex-col">
            <div className="md:hidden flex items-center justify-center">
              <Text className="font-semibold text-gray-900">
                {kidName}'s Profile
              </Text>
            </div>
            <div className="md:flex md:ml-0 ml-[-30px] cursor-pointer flex justify-start items-center  mr-[2.5rem]  md:mr-[12rem]">
              <Button className=" bg-[#14142B]  rounded-full md:h-12 md:w-12 h-8 md:mt-0 mt-3 w-8 flex justify-center items-center cursor-pointer">
                <NewAvtar src="/images/startplan.png" className="" />
              </Button>
              <div className="flex flex-col ml-3">
                <Text className="font-semibold text-md my-1  ">
                  {value && value?.activeSubscription?.Plan?.name}
                </Text>

                <div
                  onClick={
                    value?.activeSubscription?.Plan?.name ==
                    "MonkeyBox On-Demand Plan"
                      ? viewPlans
                      : planSetting
                  }
                >
                  {value &&
                  value?.activeSubscription?.Plan?.name ==
                    "MonkeyBox On-Demand Plan" ? (
                    <div
                      className=" border-[2px] border-gray-300  rounded-md p-1 flex justify-center items-center"
                      style={{ width: "116px" }}
                    >
                      <button
                        className="text-xs text-gray-700  font-semibold"
                        onClick={viewPlans}
                      >
                        View other plans
                      </button>
                    </div>
                  ) : (
                    <div
                      className="  border-[2px] border-gray-300 rounded-md p-1 flex justify-center items-center"
                      style={{ width: "116px" }}
                    >
                      <button
                        className="text-xs text-gray-700 font-semibold"
                        onClick={planSetting}
                      >
                        Plan settings
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* <div
            className="mr-5"
            onClick={() => setDropdownStatus(!dropdownStatus)}>
            <NavBarProfile src={photo} lebel={kidName} />
          </div> */}
          <div className="flex items-center ">
            <div className="md:mr-10 md:flex hidden">
              <Text> {kidName}'s Profile</Text>
            </div>
            <div
              className="mr-5 md:mt-0 mt-7   flex justify-center items-center"
              onClick={showChildList}
            >
              {}
              {/* <NavBarProfile src={photo} lebel={kidName} /> */}
              {/* <NewNavbarProfile
              src={
                photo
                  ? photo
                  : kid?.gender == "Female"
                  ? "/images/female_dummy_image.png"
                  : "/images/male_dummy_image.png"
              }
              // lebel={kidName}
            /> */}
              {kidList?.length == 1 ? (
                <img
                  src={
                    photo
                      ? photo
                      : kid?.gender == "Female"
                      ? "/images/female_dummy_image.png"
                      : "/images/male_dummy_image.png"
                  }
                  alt="First Icon"
                  className="w-12 h-12 rounded-full border-[1px] shadow-md border-gray-300 cursor-pointer"
                />
              ) : (
                <div className="relative cursor-pointer">
                  <img
                    src={
                      photo
                        ? photo
                        : kid?.gender == "Female"
                        ? "/images/female_dummy_image.png"
                        : "/images/male_dummy_image.png"
                    }
                    alt="First Icon"
                    className="w-12 h-12 rounded-full border-[1px] shadow-md border-gray-300 absolute right-4 bottom-0 z-50"
                  />
                  <img
                    src={
                      otherKid?.photo
                        ? otherKid?.photo
                        : otherKid?.gender == "Female"
                        ? "/images/female_dummy_image.png"
                        : "/images/male_dummy_image.png"
                    }
                    alt="Multikid Icon"
                    className="w-12 h-12 rounded-full border-[1px] shadow-md border-gray-300"
                    style={{ zIndex: 1 }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewHeader;
NewHeader.propTypes = {
  profilesrc: propTypes.string,
  profilename: propTypes.string,
  onclick: propTypes.func,
};
