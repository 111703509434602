import { Button, NavLink } from "atoms";
import { useRouter } from "next/router";
import React from "react";
import moment from "moment";
import { useState } from "react";
const Footer = () => {
  const router = useRouter();
  const [selectedDate] = useState(moment().format("YYYY-MM-DD"));
  let kidId = router.query?.id;
  const gotoorder = () => {
    router.push({
      pathname: `/child/${kidId}/Order-Deatils`,
      query: {
        isAutoOrderActive: false,
        date: selectedDate,
      },
    });
  };
  const planSetting = () => {
    router.push({
      pathname: `/child/${kidId}/account-settings`,
  
    });
  };
  // planSetting={() => {
  //   router.push(`/child/${selectedKid.details.id}/account-settings`);
  // }}
  return (
    <div className="h-12 bg-white z-50 w-full fixed flex justify-center items-center bottom-0 md:hidden">
      <div className="flex gap-16 justify-center items-center mx-4">
        <div className="">
          <Button
            className="font-semibold text-md "
            onClick={() => {
              router.push(`/child/${kidId}/menus`);
            }}>
            Home{" "}
          </Button>
        </div>
        <div className="">
          <Button className="font-semibold text-md " onClick={gotoorder}>
            Orders{" "}
          </Button>
        </div>
        <div className="">
          <Button
            className="font-semibold text-md "
            onClick={planSetting}>
            Profile
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Footer;
