export const Webengage = {
  USER_SIGNEDUP: "User Signed Up",
  USER_LOGGED_IN: "User Logged In",
  SESSION_STARTED: "session_started",
  NEW_KID: "New Kid",
  NEW_KID_FLOW_COMPLETED: "New Kid Flow Completed",
  LOAD_MENU: "Load Menu",
  ADDED_TO_CART: "Added to cart",
  REMOVED_FROM_CART: "Removed From Cart",
  CART_VIEWED: "Cart Viewed",
  CART_UPDATED_ADDED: "Cart Updated Added",
  CART_UPDATED_REMOVED: "Cart Updated Removed",
  CHECKOUT_STARTED: "Checkout Started",
  CHECKOUT_COMPLETED: "Checkout Completed",
  PRODUCT_VIEWED: "Product Viewed",
  ORDER_CANCELLED: "Order Cancelled",
  // ADD_ON_PAYMENT_INITIATED: "Add On Payment Initiated",
  PAYMENT_INITIATED: "Payment Initiated",
  PAYMENT_FAILURE: "Payment Failure",
  WALLET_RECHARGE: "Wallet - Recharge",
  SKIP_AND_VIEW_MENU: "Skip and View Menu",
  VIEW_OTHER_PLANS: "View Other Plans",
  VIEW_PLAN_DESCRIPTION: "View Plan description",
  PLAN_SUBSCRIBE_START: "Plan Subscribe Start",
  PLAN_CHECKOUT_INITIATED: "Plan Checkout Initiated",
  PLAN_CHECKOUT_SUCCESSFULL: "Plan Checkout Successful",
  PLAN_CHECKOUT_FAILED: "Plan Checkout Failed",
  MEAL_ITEM_SELECTED: "Item Selected",
  UPGRADE_ADDED_TO_CART: "Upgrade Added To Cart",
  ADD_ON_PAYMENT_SUCCESSFULL: "Add On Payment Successful",
  ADD_ON_PAYMENT_FAILED: "Add On Payment Failed",
  ADD_ON_PAYMENT_INITIATED: "Add On Payment Initiated",
  ADD_ON_ADDED_TO_CART: "Add-On Added To Cart",
  ADD_ON_REMOVED_FROM_CART: "Add-On Removed From Cart",
  PLACE_ORDER: "Place Order",
  PLAN_MEAL_ORDERED: "Plan Meal Ordered",
  CANCEL_ORDER: "Cancel order",
  CANCEL_MEAL_FOR_THE_DAY: "Cancel meal for the day",
  REORDER_MEAL_FOR_THE_DAY: "Reorder meal for the day",
  CART_AND_CHECKOUT: "Cart and Checkout",
  VIEW_FAQ: "View FAQ",
  CASHBACK_RECEIVED: "Cashback Recieved",
  CHILD_DETAILS_UPDATED: "Child Details Updated",
  SCHOOL_DETAILS_UPDATED: "School Details Updated",
  PROFILE_SWITCHED: "Profile Switched",
};
