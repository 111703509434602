import React, { useEffect, useState } from "react";
import Text from "../atoms/Text";
import Avatar from "../atoms/Avatar";
import { useRouter } from "next/router";
import { getAuth, removeAuth } from "../services/identity.service";
import { getKidDetails } from "../services/kids.service";
import Button from "../atoms/Button";
import Spinner from "atoms/Spinner";
function ProfileDropDown({
  handleKidSelect,
  setDropdownStatus,
  viewProfile,
  accountSetting,
  kidList,
  id,
  handleClickOutside,
}) {
  const router = useRouter();
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  useEffect(async () => {
    setLoading(true);
    const auth = getAuth();
    const response = await getKidDetails(auth.id, id, auth.token);
    setAmount(response?.data?.entity?.Wallet?.amount);
    setLoading(false);
  }, [amount]);

  return (
    <div
      className={`fixed z-50 top-20 right-1 mt-1 w-48 bg-white rounded-2 ${
        kidList.length <= 8 ? "h-auto" : "h-[500px]"
      } flex-col flex shadow-sm `}
    >
      <Text className=" w-full flex justify-start pl-5 py-3 bg-red-200 font-medium text-sm text-black">
        Balance{" "}
        {loading ? (
          <Spinner className="text-primary-700 ml-4" width="5" height="5" />
        ) : (
          // <div className="ml-4 w-5 h-5 border-b-2 border-primary-900 rounded-full animate-spin"></div>
          // &#8377; {amount / 100}
          `₹ ${amount / 100}`
        )}
      </Text>

      <Button
        onClick={viewProfile}
        className=" w-full flex justify-start ml-5 mt-3 font-medium text-sm text-black"
      >
        View profile
      </Button>

      <hr className=" mt-4 mx-5" />
      <div
        className={`overflow-y-auto h-auto ${
          kidList.length <= 8 ? "h-auto" : "h-[450px]"
        }`}
      >
        {kidList?.map((kid) => {
          return (
            <div
              key={kid.id}
              className="flex mt-5 ml-5 items-center cursor-pointer"
              onClick={() => {
                handleKidSelect(kid);
                setDropdownStatus(true);
              }}
              aria-hidden="true"
            >
              <Avatar src={kid.photo} />
              <Text className="ml-3 font-medium text-sm text-black">
                {kid.fullName}
              </Text>
            </div>
          );
        })}
      </div>
      <Button
        onClick={accountSetting}
        className=" w-full flex justify-start ml-5 mt-5 mb-3 cursor-pointer font-medium text-sm text-black"
      >
        Account Settings
      </Button>

      <Button
        onClick={() => {
          localStorage.removeItem("feedBackSkip");
          removeAuth();
          router.push("/login");
        }}
        className=" w-full flex justify-start ml-5 mt-5 mb-3 cursor-pointer font-medium text-sm text-red-400"
      >
        Log Out
      </Button>
    </div>
  );
}

export default ProfileDropDown;
