import * as HttpService from "./http.service";
import {
  GET_PLANS,
  GET_PLAN_DETAILS,
  GET_KIDS_PLANLIST,
  CANCEL_SUSCRIPTION,
  CREATE_SUSCRIPTION,
  GET_SUSCRIPTION_STATUS,
  CHANGE_MEALPLAN_STATUS,
  CHECK_SUSCRIPTION,
  ADD_MONEY_SUBSCRIBE_PLAN,
  CHECKSUBTRANSACTION_URL,
  CHECK_ACTIVE_ORDERS,
  GET_KID_DETAILS_WITH_SUBSCRIPTION_INFO,
} from "./url.service";

export const getPlans = (kidId, token, search) => {
  return HttpService.getWithAuth(GET_PLANS(kidId, search), token);
};
export const getPlansDetails = (kidId, planId, token) => {
  return HttpService.getWithAuth(GET_PLAN_DETAILS(kidId, planId), token);
};

export const getSubscriptionDetails = (parentId, kidId, token) => {
  return HttpService.getWithAuth(GET_KIDS_PLANLIST(parentId, kidId), token);
};

export const getCancleSubscriptionDetail = (
  parentId,
  kidId,
  subscriptionId,
  token
) => {
  return HttpService.getWithAuth(
    CANCEL_SUSCRIPTION(parentId, kidId, subscriptionId),
    token
  );
};
export const cancleSubscription = (parentId, kidId, subscriptionId, token) => {
  return HttpService.postWithAuth(
    CANCEL_SUSCRIPTION(parentId, kidId, subscriptionId),
    {},
    token
  );
};
export const createSubscription = (
  parentId,
  kidId,
  startDate,
  planId,
  token,
  isYearly,
  couponCode
) => {
  return HttpService.postWithAuth(
    CREATE_SUSCRIPTION(parentId, kidId),
    {
      startDate,
      planId,
      isYearly,
      couponCode,
    },
    token
  );
};
export const checkActiveSubscription = (
  parentId,
  kidId,
  startDate,
  planId,
  token
) => {
  return HttpService.postWithAuth(
    CHECK_SUSCRIPTION(parentId, kidId),
    {
      startDate,
      planId,
    },
    token
  );
};

export const checkActiveOrders = (parentId, kidId, startDate, token) => {
  return HttpService.getWithAuth(
    CHECK_ACTIVE_ORDERS(parentId, kidId, startDate),
    token
  );
};

export const createMealPlanForTheDay = (
  parentId,
  kidId,
  startDate,
  planId,
  token
) => {
  return HttpService.postWithAuth(
    CREATE_SUSCRIPTION(parentId, kidId),
    {
      startDate,
      planId,
    },
    token
  );
};
export const getSubscriptionStatus = (
  parentId,
  kidId,
  subscriptionId,
  date,
  token
) => {
  return HttpService.getWithAuth(
    GET_SUSCRIPTION_STATUS(parentId, kidId, subscriptionId, date),
    token
  );
};

export const ChangeMealPlanStatus = (
  parentId,
  kidId,
  status,
  subscriptionId,
  date,
  token
) => {
  return HttpService.postWithAuth(
    CHANGE_MEALPLAN_STATUS(parentId, kidId, status),
    { subscriptionId, date },
    token
  );
};

export const createSubscriptionaAddMoney = (
  parentId,
  kidId,
  amount,
  planId,
  startDate,
  token,
  isYearly,
  couponCode
) => {
  return HttpService.postWithAuth(
    ADD_MONEY_SUBSCRIBE_PLAN(parentId, kidId),
    {
      amount,
      planId,
      startDate,
      isYearly,
      couponCode,
    },
    token
  );
};

// export const getSubTransactionStatus = (parentId, pgTransactionId) => {
//   return HttpService.getWithAuth(
//     CHECKSUBTRANSACTION_URL(parentId, pgTransactionId),
//     token
//   );
// };

export const getSubTransactionStatus = (parentId, pgTransactionId, token) => {
  return HttpService.getWithAuth(
    CHECKSUBTRANSACTION_URL(parentId, pgTransactionId),
    token
  );
};

export const getKidWithPlansInfo = (parentId, token) => {
  return HttpService.getWithAuth(
    GET_KID_DETAILS_WITH_SUBSCRIPTION_INFO(parentId),
    token
  );
};
