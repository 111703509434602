import { useState, useRef, useEffect } from "react";
import Header from "organisms/Header";
import { useSelector, useDispatch } from "react-redux";
import { useRouter } from "next/router";
import ProfileDropDown from "../../organisms/ProfileDropDown";
import { capitalizeFirstLetter } from "services/helpers.service";
import { switchKidAction } from "../store/reducers/switchKid";
import ProfileDropDownB2B from "organisms/ProfileDropDownB2B";
import moment from "moment";
import { Webengage } from "constants/webengage";
import { getAuth } from "services/identity.service";
import ProfileDropDownSchoolB2B from "organisms/ProfileDropDownSchoolB2B";
import { getSettings } from "services/referral.service";

const HeaderComponent = ({ kidList, settings }) => {
  const router = useRouter();
  const auth = getAuth();

  const kid = kidList.find((item) => {
    return item.id === router.query.id;
  });
  const selectedKid = useSelector((state) => state.selectedKid);
  const dispatch = useDispatch();
  const profileDropDown = useRef(null);
  const [dropdownStatus, setDropdownStatus] = useState(false);

  useEffect(async () => {
    if (kid !== undefined) {
      dispatch(
        switchKidAction.updateSelectedKid({
          id: kid.id,
          fullName: capitalizeFirstLetter(kid.fullName),
          photo: kid.photo,
        })
      );
    }
  }, []);

  const handleClickOutside = (event) => {
    setDropdownStatus(false);
  };

  const handleKidSelect = (kidDetails) => {
    console.log(kidDetails, "kidDetails-");
    dispatch(
      switchKidAction.updateSelectedKid({
        id: kidDetails.id,
        fullName: capitalizeFirstLetter(kidDetails.fullName),
        photo: kidDetails.photo,
      })
    );
    router.push(`/child/${kidDetails.id}/menus`);
    window.webengage.track(Webengage.PROFILE_SWITCHED, {
      ParentId: kidDetails.parentId,
      oldKidId: router.query.id,
      NewKidId: kidDetails.id,
      NewKidName: kidDetails.fullName,
      Date: moment().format("LLL"),
    });
  };

  const isB2B = router.query.isB2B;
  return (
    <>
      {dropdownStatus &&
        (isB2B ? (
          <div ref={profileDropDown}>
            <ProfileDropDownB2B
              src="/images/kidavtar.png"
              setDropdownStatus={setDropdownStatus}
              dropdownStatus={dropdownStatus}
              handleClickOutside={handleClickOutside}
              viewProfile={() => {
                router.push(
                  `/child/${selectedKid.details.id}/account-settings`
                );
              }}
              accountSetting={() => {
                router.push("/account-details");
              }}
              kidList={kidList}
              handleKidSelect={handleKidSelect}
            />
          </div>
        ) : auth.roles.includes("Parent Offline B2B") ? (
          <div ref={profileDropDown}>
            <ProfileDropDownSchoolB2B
              src="/images/kidavtar.png"
              setDropdownStatus={setDropdownStatus}
              dropdownStatus={dropdownStatus}
              handleClickOutside={handleClickOutside}
              viewProfile={() => {
                router.push(
                  `/child/${selectedKid.details.id}/account-settings`
                );
              }}
              accountSetting={() => {
                router.push("/account-details");
              }}
              kidList={kidList}
              handleKidSelect={handleKidSelect}
            />
          </div>
        ) : (
          <div ref={profileDropDown}>
            <ProfileDropDown
              src="/images/kidavtar.png"
              setDropdownStatus={setDropdownStatus}
              dropdownStatus={dropdownStatus}
              handleClickOutside={handleClickOutside}
              id={selectedKid.details.id}
              viewProfile={() => {
                router.push(
                  `/child/${selectedKid.details.id}/account-settings`
                );
              }}
              accountSetting={() => {
                router.push("/account-details");
              }}
              kidList={kidList}
              handleKidSelect={handleKidSelect}
            />
          </div>
        ))}
      <Header
        isB2B={isB2B}
        kidName={selectedKid.details.fullName}
        photo={selectedKid.details.photo}
        orderHref={`/child/${selectedKid.details.id}/orders`}
        reportHref={`/school/${router.query?.id}/report`}
        // orderHref={"orders"}
        settings={settings}
        setDropdownStatus={setDropdownStatus}
        dropdownStatus={dropdownStatus}
        onLogoClick={() => {
          if (isB2B) {
            router.push(`/canteen/child/${selectedKid.details.id}/menus`);
          } else if (auth.roles.includes("Parent Offline B2B")) {
            router.push(`/school/${router.query?.id}/menus`);
          } else {
            router.push(`/child/${selectedKid.details.id}/menus`);
          }
        }}
      />
    </>
  );
};

export default HeaderComponent;
