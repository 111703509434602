import React from "react";
import PropTypes from "prop-types";
import Text from "../atoms/Text";
import Avatar from "../atoms/Avatar";

function NavBarProfile({ src, lebel, onClick }) {
  return (
    <div onClick={onClick} className="w-22 h-10 flex items-center ">
      <Text className="text-xs font-medium text-gray-500 mr-1 sm:mr-4 capitalize text-[12px]">
        {lebel}
      </Text>
      <Avatar src={src} />
    </div>
  );
}

export default NavBarProfile;

NavBarProfile.propTypes = {
  src: PropTypes.string,
  lebel: PropTypes.string,
  onClick: PropTypes.func,
};
