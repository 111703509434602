import { configureStore } from '@reduxjs/toolkit';
import storage from './storage';
import {combineReducers} from 'redux'; 
import { persistReducer} from 'redux-persist';
import selectedKidReducer from './reducers/switchKid';
import editOrderReducer from "./reducers/editOrder";

const reducers = combineReducers({
  selectedKid: selectedKidReducer,
  editOrder: editOrderReducer,
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false // ignores serializable check
    }),
});

export default store;