import * as HttpService from "./http.service";
import {
  GET_PARENT,
  GET_PARENT_BTB,
  GET_MENU_DATES_BTB,
  GET_MENU_DATA_BTB,
  CREATE_ORDER_BTB_KIDS,
  CANCEL_ALL_ORDERS_BTB,
  GET_RATTING_INFO,
  GET_PARENT_FEEDBACK_INFO,
  GET_FEEFBACK
} from "./url.service";

export const getParent = (userId, token) => {
  return HttpService.getWithAuth(GET_PARENT(userId), token);
};

export const getParentBTB = (userId, token) => {
  return HttpService.getWithAuth(GET_PARENT_BTB(userId), token);
};

export const getMenuDatesBtb = (parentId, kidId, token) => {
  return HttpService.getWithAuth(GET_MENU_DATES_BTB(parentId, kidId), token);
};

export const getMenuData = (parentId, kidId, date, token) => {
  return HttpService.getWithAuth(
    GET_MENU_DATA_BTB(parentId, kidId, date),
    token
  );
};

export const createOrder = (parentId, kidId, menuId, token) => {
  return HttpService.postWithAuth(
    CREATE_ORDER_BTB_KIDS(parentId, kidId),
    { menuId },
    token
  );
};

export const cancelAllOrder = (parentId, kidId, date, token) => {
  return HttpService.postWithAuth(
    CANCEL_ALL_ORDERS_BTB(parentId, kidId, date),
    {},
    token
  );
};

export const getAllRatting = (userId, token) => {
  return HttpService.getWithAuth(GET_RATTING_INFO(userId), token);
};



export const getParentsFeedback = (userId,token) => {
  return HttpService.getWithAuth(GET_FEEFBACK(userId),token);
};

// getWithOutAuth